const shopUrl =
  'https://stembionix.com/products/stembionix-membership-1-year?variant=37720726864058';

const whiteKit =
  'https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/kit_white.png?alt=media&token=ab608136-89e0-49ad-8a56-a365c921292b';

// const blueKit;

export const KitHero = () => {
  return (
    <div className='lg:relative bg-white'>
      <div className='relative w-full h-48 sm:h-64 md:h-64 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full'>
        <img
          className='absolute inset-0 w-full h-full object-contain'
          src={whiteKit}
          alt='Stembionix kit'
        />
      </div>
      <div className='mx-auto max-w-7xl w-full xs:pt-4 sm:pt-16 pb-20 text-center lg:py-24 lg:text-left'>
        <div className='px-4 lg:w-1/2 sm:px-8 xl:pr-16'>
          <h2 className='text-4xl tracking-tight leading-10 text-gray-800 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl'>
            <span className='text-skyblue'>Personal Stem Cells</span>
          </h2>
          <h2 className='text-3xl tracking-tight leading-10 text-gray-800 sm:text-4xl sm:leading-none md:text-5xl lg:text-4xl xl:text-5xl'>
            An At-Home Cheek Swab Away
          </h2>
          <p className='mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl'>
            Our home-based cell collection kit is all you need to start the
            process of getting your own personalized stem cells.
          </p>
          <div className='mt-10 sm:flex sm:justify-center lg:justify-start'>
            <div className='rounded-md shadow'>
              <a
                href={shopUrl}
                className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-skyblue hover:bg-sky-600 hover:text-gray-800 focus:outline-none focus:border-gray-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10'>
                Get yours
              </a>
            </div>
            <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
              <a
                href='https://stembionix.com/pages/process'
                className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-skyblue bg-blue-100 hover:text-skyblue focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10'>
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
