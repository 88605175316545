import React from 'react';
import { BuyMembershipCTA } from '../components/cta/BuyMembershipCTA';
import { ImageTextCTA } from '../components/cta/ImageTextCTA';
import { MarketIconCallout } from '../components/MarketIconCallout';

export const Marketplace = () => {
  return (
    <div className='relative py-16 bg-white overflow-hidden'>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='text-lg max-w-prose mx-auto'>
          <h1>
            <span className='block text-base text-center text-skyblue font-semibold tracking-wide uppercase'>
              Introducing
            </span>
            <span className='mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
              Stembionix <span className='text-skyblue'>Stem Cell </span>
              Marketplace
            </span>
          </h1>
          <p className='mt-8 text-xl text-gray-500 leading-8'>
            We are proud to introduce a new - and a world's first - a
            marketplace to sell, buy, and trade stem cells and cell types.
          </p>
        </div>
        <MarketIconCallout />
        <div className='mt-6 prose prose-skyblue prose-lg text-gray-500 mx-auto'>
          {/*  */}
          <h2>How it works</h2>
          <p>
            After signing up for{' '}
            <a href='https://portal.stembionix.com'>
              <span className='text-skyblue'>Stembionix Portal</span>
            </a>{' '}
            you will be able to enter the marketplace. If you have stem cells,
            you will be given the option to sell those cells on the marketplace.
            Even if you don't have stem cells, you'll still have the option to
            buy stem cells or buy options that will enable to you get those same
            stem cells in the future.
          </p>
          {/* Examples */}
          <div className='relative border'>
            <h2>Selling Examples</h2>

            <img src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/noun_transaction_2757283.svg?alt=media&token=11e26b16-4769-4782-8e4f-ae50127f31b7' />
            {/* image */}
            <div></div>
            <div>
              Bob bought a stem cell collection kit and membership. He now has
              10 million stem cells stored. Bob can either 1) sell some of these
              stem cells or 2) sell options to grow more of his stem cells to
              others.
            </div>
          </div>

          <div className='mt-10' />

          <div className='relative border'>
            <h2>Buying Examples</h2>

            <img src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/noun_transaction_2757283.svg?alt=media&token=11e26b16-4769-4782-8e4f-ae50127f31b7' />
            {/* image */}
            <div></div>
            <div>
              Bob bought a stem cell collection kit and membership. He now has
              10 million stem cells stored. Bob can either 1) sell some of these
              stem cells or 2) sell options to grow more of his stem cells to
              others.
            </div>
          </div>
        </div>

        <div className='mt-4' />
        <BuyMembershipCTA />
        {/* <ExchangeCTA /> */}

        {/*  */}
      </div>
    </div>
  );
};
