import React from 'react';
import { Link } from 'react-router-dom';
// import FirebaseClient from '../firebase/firebase_client';
// import { Dashboard } from './Dashboard';

const pages = [
  { title: 'Home', to: '/home' },
  { title: 'About', to: '/about' },
  { title: 'Marketplace', to: '/marketplace' },
  { title: 'Use Cases', to: '/use-cases' },
  { title: 'Industry', to: '/industry' },
  { title: 'Early Adopter', to: '/early' },
  { title: 'Hobbyist', to: '/hobbyist' },
  { title: 'Cosmetic', to: '/cosmetic' },
  { title: 'Medical', to: '/medical' },
  { title: 'Process', to: '/process' },
];

export const LandingPage = () => {
  // const { currentUser } = FirebaseClient.auth || undefined;

  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('Current user', currentUser);
  // }

  return (
    <div className='relative max-w-7xl mx-auto mt-4 py-2 px-4 sm:px-6 lg:px-8'>
      <h1 className='text-3xl leading-9 m-2'>Landing Pages</h1>
      <ul className='mt-2'>
        {pages.map((page) => {
          const { title, to } = page;
          return (
            <li key={to}>
              <Link
                to={to}
                className='block m-2 p-2 border rounded-xl shadow hover:bg-skyblue'>
                {title} Page
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );

  // return <HomePage />;

  // if (currentUser) {
  //   return <Dashboard />;
  // }

  // return <HomePage />;
};
