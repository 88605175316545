import React from 'react';
import { FAQ } from '../components/FAQ';
import { WhyStemCells } from '../components/WhyStemCells';
import { PageContainer } from './PageContainer';

type Item = { title: string; about: string; icon: JSX.Element };

const sectionOneItems: Item[] = [
  {
    title: 'Grown from your own cells',
    about:
      'We take your adult cells and transform them back into pluripotent stem cells. Research has shown great potential for the use of these cells in cosmetic as well as in the field of regenerative medicine.',
    icon: (
      <svg
        className='h-6 w-6'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
        aria-hidden='true'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9'
        />
      </svg>
    ),
  },
  {
    title: 'Can turn into any other cell type',
    about:
      'Stem cell can give rise to every other cell type in the body (such as neurons, heart, pancreatic, and liver cells), they represent a single source of cells that could be used to replace those lost to damage or disease.',
    icon: (
      <svg
        className='h-6 w-6'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z'
        />
      </svg>
    ),
  },
  {
    title: 'Use for medical and cosmetic purposes',
    about:
      'Stem cells are used in medical therapies ranging from cancer treatments to knee rejuvenation. Stem cells are also used in comestic applications such as the removal of wrinkles.',
    icon: (
      <svg
        className='h-6 w-6'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'
        aria-hidden='true'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M13 10V3L4 14h7v7l9-11h-7z'
        />
      </svg>
    ),
  },
];

const sectionTwoItems: Item[] = [
  {
    title: 'Ad hoc usage',
    about:
      'Because we keep your stem cells at the ready, you are able to use or deploy in any of our - or connected - services.',
    icon: (
      <svg
        className='h-6 w-6'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
        />
      </svg>
    ),
  },
  {
    title: 'Third parties',
    about:
      'We are always expanding our trusted third party networks in which you will be able to use your stem cells.',
    icon: (
      <svg
        className='h-6 w-6'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z'
        />
      </svg>
    ),
  },
  {
    title: 'Concierge Services',
    about:
      'We can help connect you not only with trusted parties offering stem cell services, but we can take all the work out of booking and arranging such treatments.',
    icon: (
      <svg
        className='h-6 w-6'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
        />
      </svg>
    ),
  },
];

const sectionThreeItems: Item[] = [
  {
    title: 'Find Research Projects',
    about:
      'Search the exchange to find research projects that you want to help support.',
    icon: (
      <svg
        className='h-6 w-6'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
        />
      </svg>
    ),
  },
  {
    title: 'Deploy Your Cells',
    about:
      'Deploy your cells to your select projects and potentially recieve money for doing so.',
    icon: (
      <svg
        className='h-6 w-6'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z'
        />
      </svg>
    ),
  },
  {
    title: 'Expand the Exchange and Learn More',
    about:
      'Add projects that you want to see solved. Invite other researchers and users to the exchange. Follow along the progress of your selected projects.',
    icon: (
      <svg
        className='h-6 w-6'
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke='currentColor'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          d='M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
        />
      </svg>
    ),
  },
];

const DLItem = ({ item }: { item: Item }) => {
  return (
    <div className='flex'>
      <div className='flex-shrink-0'>
        <div className='flex items-center justify-center h-12 w-12 rounded-md bg-skyblue text-white'>
          {item.icon}
        </div>
      </div>
      <div className='ml-4'>
        <dt className='text-lg leading-6 font-medium text-gray-900'>
          {item.title}
        </dt>
        <dd className='mt-2 text-base text-gray-500'>{item.about}</dd>
      </div>
    </div>
  );
};

export const AboutPage = () => {
  return (
    <PageContainer helmet='About'>
      <img
        src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/Stembionix.png?alt=media&token=861c4568-5614-47f1-bcac-4be281047ef3'
        alt=''
      />
      <section className=''>
        <div className='py-16 overflow-hidden lg:py-24'>
          <div className='relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
            <svg
              className='hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4'
              width='404'
              height='784'
              fill='none'
              viewBox='0 0 404 784'
              aria-hidden='true'>
              <defs>
                <pattern
                  id='b1e6e422-73f8-40a6-b5d9-c8586e37e0e7'
                  x='0'
                  y='0'
                  width='20'
                  height='20'
                  patternUnits='userSpaceOnUse'>
                  <rect
                    x='0'
                    y='0'
                    width='4'
                    height='4'
                    className='text-blue-100'
                    fill='currentColor'
                  />
                </pattern>
              </defs>
              <rect
                width='404'
                height='784'
                fill='url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)'
              />
            </svg>

            <div className='relative'>
              <h2 className='text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
                Leading the <span className='text-skyblue'>Stem Cell </span>
                Revolution
              </h2>
              <p className='mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500'>
                We are creating the means to enable you to take full advantage
                of the science of stem cells today and tomorrow.
              </p>
            </div>

            <div className='relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center'>
              <div className='relative'>
                <h3 className='text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl'>
                  What are Stem Cells?
                </h3>
                <p className='mt-3 text-lg text-gray-500'>
                  Stem cells are cells with the potential to develop into many
                  different types of cells in the body. They serve as a repair
                  system for the body. There are two main types of stem cells:
                  embryonic stem cells and adult stem cells.
                </p>
                <dl className='mt-10 space-y-10'>
                  {sectionOneItems.map((item) => {
                    return <DLItem key={item.title} item={item} />;
                  })}
                </dl>
              </div>

              <div className='mt-10 -mx-4 relative lg:mt-0' aria-hidden='true'>
                <svg
                  className='absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden'
                  width='784'
                  height='404'
                  fill='none'
                  viewBox='0 0 784 404'>
                  <defs>
                    <pattern
                      id='ca9667ae-9f92-4be7-abcb-9e3d727f2941'
                      x='0'
                      y='0'
                      width='20'
                      height='20'
                      patternUnits='userSpaceOnUse'>
                      <rect
                        x='0'
                        y='0'
                        width='4'
                        height='4'
                        className='text-blue-100'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width='784'
                    height='404'
                    fill='url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)'
                  />
                </svg>
                <img
                  className='relative mx-auto'
                  width='490'
                  src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/Final_stem_cell_differentiation%20(1).svg?alt=media&token=5a2c1f6e-1638-4f86-83d8-224d0c5de01c'
                  alt='Stem cell differentiation schematic'
                />
              </div>
            </div>

            <svg
              className='hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12'
              width='404'
              height='784'
              fill='none'
              viewBox='0 0 404 784'
              aria-hidden='true'>
              <defs>
                <pattern
                  id='64e643ad-2176-4f86-b3d7-f2c5da3b6a6d'
                  x='0'
                  y='0'
                  width='20'
                  height='20'
                  patternUnits='userSpaceOnUse'>
                  <rect
                    x='0'
                    y='0'
                    width='4'
                    height='4'
                    className='text-blue-100'
                    fill='currentColor'
                  />
                </pattern>
              </defs>
              <rect
                width='404'
                height='784'
                fill='url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)'
              />
            </svg>
            {/*  */}
            <div className='relative mt-12 sm:mt-16 lg:mt-24'>
              <div className='lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center'>
                <div className='lg:col-start-2'>
                  <h3 className='text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl'>
                    Your Cells Always at the Ready
                  </h3>
                  <p className='mt-3 text-lg text-gray-500'>
                    We maintain your stem cells so that you can make use of them
                    at a moments notice.
                  </p>
                  <dl className='mt-10 space-y-10'>
                    {sectionTwoItems.map((item) => {
                      return <DLItem key={item.title} item={item} />;
                    })}
                  </dl>
                </div>

                <div className='mt-10 -mx-4 relative lg:mt-0 lg:col-start-1'>
                  <svg
                    className='absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden'
                    width='784'
                    height='404'
                    fill='none'
                    viewBox='0 0 784 404'
                    aria-hidden='true'>
                    <defs>
                      <pattern
                        id='e80155a9-dfde-425a-b5ea-1f6fadd20131'
                        x='0'
                        y='0'
                        width='20'
                        height='20'
                        patternUnits='userSpaceOnUse'>
                        <rect
                          x='0'
                          y='0'
                          width='4'
                          height='4'
                          className='text-gray-200'
                          fill='currentColor'
                        />
                      </pattern>
                    </defs>
                    <rect
                      width='784'
                      height='404'
                      fill='url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)'
                    />
                  </svg>
                  <img
                    className='relative mx-auto'
                    width='490'
                    src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/800px-Human_embryonic_stem_cells_only_A.png?alt=media&token=9d33feac-0331-4888-9ec9-acf053337631'
                    alt='Human embryonic stem cell'
                  />
                </div>
              </div>
            </div>
            <div className='relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center'>
              <div className='relative'>
                <h3 className='text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl'>
                  Stembionix Exchange Marketplace
                </h3>
                <p className='mt-3 text-lg text-gray-500'>
                  Not only can you use your stem cells directly or via a
                  third-party, but you can also deploy your cells to scientist
                  and researchers to help them meet a need (and get paid for
                  it!).
                </p>
                <dl className='mt-10 space-y-10'>
                  {sectionThreeItems.map((item) => {
                    return <DLItem key={item.title} item={item} />;
                  })}
                </dl>
              </div>

              <div className='mt-10 -mx-4 relative lg:mt-0' aria-hidden='true'>
                <svg
                  className='absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden'
                  width='784'
                  height='404'
                  fill='none'
                  viewBox='0 0 784 404'>
                  <defs>
                    <pattern
                      id='ca9667ae-9f92-4be7-abcb-9e3d727f2941'
                      x='0'
                      y='0'
                      width='20'
                      height='20'
                      patternUnits='userSpaceOnUse'>
                      <rect
                        x='0'
                        y='0'
                        width='4'
                        height='4'
                        className='text-blue-100'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width='784'
                    height='404'
                    fill='url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)'
                  />
                </svg>
                <img
                  className='relative mx-auto'
                  width='490'
                  src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/Screen%20Shot%202020-12-28%20at%207.17.45%20PM.png?alt=media&token=1aa782b3-b573-4748-9616-ee099d6fecd5'
                  alt='Stem cell differentiation schematic'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <WhyStemCells />
      <FAQ />

      {/* logo section */}
      {/* TODO: idea is to show our other companies, supporters, network for additional consumer trust */}
      {/* <section>
        <LogoCloud />
      </section> */}
    </PageContainer>
  );
};
