/**
 * TODO: want to be able to flip the image left to right
 * need to use the grid trick for this to work
 */
export const ImageTextCTA = ({
  header,
  subheader,
  text,
  imageOnRight = false,
  img = 'https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/plasmid.png?alt=media&token=31f60d40-e7c1-433b-8570-a5cc7c491706',
  link,
}: {
  header: string;
  subheader: string;
  text: string;
  imageOnRight?: boolean;
  img?: string;
  link?: string;
}) => {
  return (
    <div className='relative bg-white'>
      <div className='m-0 grid grid-cols-1 md:grid-cols-2 md:grid-flow-row-dense'>
        {/* Image box */}
        <div className={`${imageOnRight && 'md:col-start-2'} bg-sky-600`}>
          <img className='p-2 mx-auto object-cover' src={img} alt='' />
        </div>
        {/* Text box  */}
        <div
          className={`${
            imageOnRight && 'md:col-start-1'
          } bg-sky-700 px-4 py-12 sm:px-6 lg:px-8 lg:py-12`}>
          <div className='mx-auto md:w-1/2'>
            <h2 className='text-base font-semibold uppercase tracking-wider text-gray-900'>
              {subheader}
            </h2>
            <p className='mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl'>
              {header}
            </p>
            <p className='mt-3 text-lg text-gray-300'>{text}</p>
            <div className='mt-8'>
              <div className='inline-flex rounded-md shadow'>
                <a
                  href={link || '#'}
                  className='inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50'>
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
