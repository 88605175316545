const Icon = ({
  header,
  text,
  img,
}: {
  img: string;
  header: string;
  text: string;
}) => {
  return (
    <div className='p-4 flex flex-col items-center overflow-hidden'>
      <img className='p-2' src={img} />
      <h1 className='prose text-2xl'>{header}</h1>
      <p className='prose text-md'>{text}</p>
    </div>
  );
};

export const MarketIconCallout = () => {
  return (
    <div className='bg-sky-100 py-4'>
      <div className='ml-20'>
        <h2 className='prose text-2xl leading-7'>
          World's first <span className='text-skyblue'>stem cell</span>{' '}
          marketplace
        </h2>
        <p className='prose'>
          We are building the world's first stem cell marketplace that will
          enable you to do all the things a normal marketplace does.{' '}
          <a href='https://stembionix.com/pages/marketplace'>
            <em className='text-skyblue'>Learn more</em>
          </a>{' '}
        </p>
      </div>
      {/* <div className='grid grid-cols-1 md:grid-cols-3 gap-3'> */}
      <div className='flex justify-around'>
        <Icon
          header='Sell'
          text='Sell your stems with various conditions'
          img='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/noun_selling_2596893.svg?alt=media&token=ebcae7e9-e9a7-4bfe-9797-94a66776f1a9'
        />
        <Icon
          header='Buy'
          text='Buy cells or options to grow cells'
          img='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/noun_buy_1168777.svg?alt=media&token=d210c369-8269-41f2-990e-0fe16c124e5a'
        />
        <Icon
          header='Trade'
          text='Trade cells and options'
          img='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/noun_diversify_1584266.svg?alt=media&token=66ba3ba4-3bb1-45a9-8940-d7b4b72caf05'
        />
      </div>
    </div>
  );
};
