import { Helmet } from 'react-helmet';

/**
 * Wrapper for Pages
 */
export const PageContainer = ({
  helmet,
  children,
}: {
  helmet?: string;
  children: any;
}) => {
  return (
    <div className='bg-white overflow-hidden'>
      {helmet && (
        <Helmet>
          <title>{helmet}</title>
        </Helmet>
      )}
      <div className='relative max-w-7xl mx-auto mt-0 py-2 px-4 sm:px-6 lg:px-8'>
        {children}
      </div>
    </div>
  );
};
