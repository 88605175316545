import { KitHero } from '../components/heroes/KitHero';
import { PageContainer } from './PageContainer';

const insideKitUrl =
  'https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/kitinside.png?alt=media&token=41af5f99-82b7-4665-9b7d-8a98a26c200d';

const steps: { number: string; title: string; text: string }[] = [
  {
    number: '1',
    title: 'Order',
    text:
      'Choose from either our personal human kits for yourself or one of our pet kits. Your stem cell collection kit is the same for both services and we expect to ship both by summer 2021.',
  },
  {
    number: '2',
    title: 'Swab',
    text:
      'Follow kit instructions to take a cheek swab and place the swab in the tube provided — all from home. Register your collection kit using the barcode so we know it belongs to you, and mail it back to our lab in the pre-paid package.',
  },
  {
    number: '3',
    title: 'Harness',
    text:
      'In approximately 6-8 weeks, we will send you an email to let you know your reports are ready in your online account. Log in and start discovering what your stem cells say about you and start using them.',
  },
];

const StepsCard = ({ step }: { step: typeof steps[0] }) => {
  const { number, title, text } = step;
  return (
    <div className='flex flex-col bg-white rounded-lg shadow-lg overflow-hidden'>
      {/* White heading */}
      <div className='px-6 py-8 sm:p-10 sm:pb-6'>
        <div className='flex flex-row mt-4'>
          <div className='text-8xl -mt-4 font-extrabold text-skyblue'>
            {number}
          </div>
          <div className='ml-2'>
            <h3 className='text-4xl'>{title}</h3>
            <p className='mt-5 text-lg text-gray-500'>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const WhatsInTheKit = () => {
  return (
    <div className='mt-4 bg-gray-50 text-center'>
      <h1 className='text-7xl'>What's in the kit.</h1>
      <img className='mt-2 mx-auto object-cover' alt='' src={insideKitUrl} />
    </div>
  );
};

// explain how the stem cells are made
const HowItWorks = () => {
  return (
    <div>
      <div className='mt-8 grid grid-cols-1 md:grid-cols-5 bg-skyblue'>
        <div className='col-span-2'>
          <img
            className='p-4'
            alt=''
            src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/Screen%20Shot%202020-12-28%20at%204.03.13%20PM.png?alt=media&token=20153f70-f685-455c-b1bf-e838a6cc85c1'
          />
        </div>
        <div className='col-span-3 text-center'>
          <h1 className='text-7xl'>How it works</h1>
        </div>
      </div>
      {/* OLD CONTENT COPY GOES HERE */}
    </div>
  );
};

export const ThreeSteps = ({ showButton = true }: { showButton?: boolean }) => {
  return (
    <div className='bg-sky-900'>
      <div className='pt-12 sm:pt-16 lg:pt-24'>
        <div className='max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8'>
          <div className='max-w-3xl mx-auto space-y-2 lg:max-w-none'>
            <p className='text-3xl font-extrabold text-sky-100 sm:text-4xl lg:text-5xl'>
              Three steps. It's simple.
            </p>
            <p className='text-xl text-gray-200'>
              All from home. No blood. No needles. Just a cheek swab.
            </p>
          </div>
        </div>
      </div>
      <div className='mt-8 pb-12 bg-sky-500 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24'>
        <div className='relative'>
          <div className='absolute inset-0 h-3/4 bg-sky-900'></div>
          <div className='relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            {/* Grid  */}
            <div className='max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-3 lg:gap-5 lg:space-y-0'>
              {/* Card */}
              {steps.map((step) => (
                <StepsCard key={step.number} step={step} />
              ))}
            </div>
          </div>
        </div>
        {showButton && (
          <div className='mt-10 text-center'>
            <button className='bg-white px-8 py-2 rounded-xl'>
              Learn more
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export const ProcessPage = () => {
  return (
    <div>
      {/* Hero */}
      {/* <KitHero /> */}
      {/* Outline basic steps via card grid  */}
      <ThreeSteps showButton={false} />

      {/* WHAT'S IN THE KITS */}
      <WhatsInTheKit />

      {/* HOW IT WORKS FLOW */}
      {/* <HowItWorks /> */}

      {/* OLD */}
      <div className='relative py-16 bg-white overflow-hidden'>
        <div
          className='relative h-full text-lg max-w-prose mx-auto'
          aria-hidden='true'></div>
      </div>
      {/* <!--  --> */}
      <div className='relative px-4 sm:px-6 lg:px-8'>
        {/* <!--  --> */}
        <div className='mt-0 prose prose-skyblue prose-lg text-gray-500 mx-auto'>
          {/* <!-- Part 1  --> */}
          <h2>1. Order</h2>
          <p>Order a membership kit.</p>
          <p>
            As part of your membership, you'll have access to the Stembionix
            Portal where you'll be able to track your kit and see the status of
            your stem cells.
          </p>
          <p>
            The kit that is sent includes everything that you'll need to take
            your at-home cheek swab.
          </p>

          {/* <!-- Part 2 --> */}
          <h2>2. Swab</h2>
          <p>
            You'll receive the Stembionix Kit that includes a swab collection
            system as well as a shipping container to keep your cells alive on
            the way back to Stembionix HQ.
          </p>
          <p>The actual swab is super simple as seen below.</p>

          <figure>
            <img
              className='w-full rounded-lg'
              src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/31297446_177167282938344_2920517348270014464_n-1.png?alt=media&token=573d6d46-0c86-482b-b44d-803d0358f11a'
              alt=''
              width='1310'
              height='873'
            />
            <figcaption>Sample instructions for cheek swab</figcaption>
          </figure>

          {/* <!-- Part 3 --> */}
          <h2>3. Harnass</h2>
          <p>
            Once your Kit arrives back at Stembionix, we begin the process of
            taking your cheek cells and turning them into stem cells.
          </p>
          <p>
            todo: short process. can have longer educational page. but this is
            mainly PR for extreme new customers
          </p>

          <p>Process of cells back to stem cells</p>

          <div className='grid grid-cols-1 md:grid-cols-3'>
            <img
              className='w-full rounded-lg'
              src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/IMG_2896.jpg?alt=media&token=2cdc275f-c93e-43f9-945a-8068271fb5ae'
              alt=''
              width='1310'
              height='873'
            />
            <div style={{ all: 'revert' }}>
              {/* <!-- <div className="text-black">
            <svg
              className="h-20 w-20 auto-mx"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div> --> */}
            </div>
            <img src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/800px-Human_embryonic_stem_cells_only_A.png?alt=media&token=9d33feac-0331-4888-9ec9-acf053337631' />
          </div>

          {/* <!-- Part 4 --> */}
          <h3>How you can use them</h3>
          <p>
            You can use the stem cells directly either by having some shipped to
            you or to a third party.
          </p>
          <p>
            We can help arrange shipment to third parties for cosmetic and
            medical procedures.
          </p>
          <p>
            You can also have the stem cells differentiated into other cell
            types and have those shipped.
          </p>

          <figure>
            <img
              className='w-full rounded-lg'
              src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/Screen%20Shot%202020-12-23%20at%205.56.27%20PM.png?alt=media&token=765300aa-e598-42c1-a404-a1a537093fef'
              alt=''
              width='1310'
              height='873'
            />
            <figcaption>Stembionx Portal screenshot</figcaption>
          </figure>
        </div>
      </div>
    </div>
  );
};
