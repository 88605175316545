type Item = { q: string; a: string };
const faqs: Item[] = [
  {
    q: 'How do you make stem cells?',
    a:
      'We derive stem cells from your normal adult cells. We take a swab of your inside cheek that collects some cells. We then are able to - through application of certain chemicals - tell those tells to turn back into stem cells.',
  },
  {
    q: 'Is stem cell collection safe?',
    a:
      'Yes. The collection of your cells via the cheek swab is safe as it only involves using a cotton swab rubbed inside your mouth.',
  },
];

const DLItem = ({ item }: { item: Item }) => {
  return (
    <div>
      <dt className='text-lg leading-6 font-medium text-gray-900'>{item.q}</dt>
      <dd className='mt-2 text-base text-gray-500'>{item.a}</dd>
    </div>
  );
};

export const FAQ = () => {
  return (
    <div className='bg-white'>
      <div className='max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8'>
        <div className='lg:grid lg:grid-cols-3 lg:gap-8'>
          <div>
            <h2 className='text-3xl font-extrabold text-gray-900'>
              Frequently asked questions
            </h2>
            <p className='mt-4 text-lg text-gray-500'>
              Can’t find the answer you’re looking for? Reach out to our{' '}
              <a
                href='https://stembionix.com/pages/contact'
                className='font-medium text-skyblue'>
                customer support
              </a>{' '}
              team.
            </p>
          </div>

          <div className='mt-12 lg:mt-0 lg:col-span-2'>
            <dl className='space-y-12'>
              {faqs.map((faq) => {
                return <DLItem key={faq.q} item={faq} />;
              })}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
