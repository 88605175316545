import { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AboutPage } from '../pages/AboutPage';
import { LandingPage } from '../pages/LandingPage';
import { IndustryPage } from '../pages/Industry';
import { ExchangePage } from '../pages/Exchange';
import { HobbyistPage } from '../pages/Hobbyist';
import { EarlyAdopterPage } from '../pages/EarlyAdopter';
import { CosmeticPage } from '../pages/Cosmetic';
import { MedicalPage } from '../pages/Medical';
import { ProcessPage } from '../pages/Process';
import { TestPage } from '../pages/Test';
import { HomePage } from '../pages/HomePage';
import { Marketplace } from '../pages/Marketplace';
import { UseCases } from '../pages/UseCases';

export class Router extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
    if (process.env.NODE_ENV !== 'production') {
      console.group('ROUTER ERROR: COMPONENT DID CATCH');
      console.error(error, info);
      console.groupEnd();
    }
  }

  render() {
    return (
      <Switch>
        {/*  */}
        <Route exact path='/' component={LandingPage} />
        {/* Landing Pages */}
        <Route exact path='/home' component={HomePage} />
        <Route exact path='/about' component={AboutPage} />
        <Route exact path='/cosmetic' component={CosmeticPage} />
        <Route exact path='/early' component={EarlyAdopterPage} />
        <Route exact path='/exchange' component={ExchangePage} />
        <Route exact path='/hobbyist' component={HobbyistPage} />
        <Route exact path='/industry' component={IndustryPage} />
        <Route exact path='/medical' component={MedicalPage} />
        <Route exact path='/process' component={ProcessPage} />
        <Route exact path='/marketplace' component={Marketplace} />
        <Route exact path='/use-cases' component={UseCases} />
        {/*  */}
        <Route exact path='/test' component={TestPage} />
        <Route path='/:slug'>
          <Redirect to='/' />
        </Route>
      </Switch>
    );
  }
}
