import { BuyMembershipCTA } from '../components/cta/BuyMembershipCTA';
import { ExchangeCTA } from '../components/cta/ExchangeCTA';

export const ExchangePage = () => {
  return (
    <div className='relative py-16 bg-white overflow-hidden'>
      <div className='hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full'>
        <div
          className='relative h-full text-lg max-w-prose mx-auto'
          aria-hidden='true'>
          <svg
            className='absolute top-12 left-full transform translate-x-32'
            width='404'
            height='384'
            fill='none'
            viewBox='0 0 404 384'>
            <defs>
              <pattern
                id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                x='0'
                y='0'
                width='20'
                height='20'
                patternUnits='userSpaceOnUse'>
                <rect
                  x='0'
                  y='0'
                  width='4'
                  height='4'
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width='404'
              height='384'
              fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
            />
          </svg>
          <svg
            className='absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32'
            width='404'
            height='384'
            fill='none'
            viewBox='0 0 404 384'>
            <defs>
              <pattern
                id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                x='0'
                y='0'
                width='20'
                height='20'
                patternUnits='userSpaceOnUse'>
                <rect
                  x='0'
                  y='0'
                  width='4'
                  height='4'
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width='404'
              height='384'
              fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
            />
          </svg>
          <svg
            className='absolute bottom-12 left-full transform translate-x-32'
            width='404'
            height='384'
            fill='none'
            viewBox='0 0 404 384'>
            <defs>
              <pattern
                id='d3eb07ae-5182-43e6-857d-35c643af9034'
                x='0'
                y='0'
                width='20'
                height='20'
                patternUnits='userSpaceOnUse'>
                <rect
                  x='0'
                  y='0'
                  width='4'
                  height='4'
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width='404'
              height='384'
              fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
            />
          </svg>
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='text-lg max-w-prose mx-auto'>
          <h1>
            <span className='block text-base text-center text-skyblue font-semibold tracking-wide uppercase'>
              Introducing
            </span>
            <span className='mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
              Stembionix <span className='text-skyblue'>Stem Cell </span>
              Exchange Marketplace
            </span>
          </h1>
          <p className='mt-8 text-xl text-gray-500 leading-8'>
            There are many ways that you can make use of your personalized stem
            cells. We are proud to introduce a new - and first for the industry
            - that allows you to enter/use your stem cells in an exchange.
          </p>
        </div>
        <div className='mt-6 prose prose-skyblue prose-lg text-gray-500 mx-auto'>
          <h2>The Problem</h2>

          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='max-w-3xl mx-auto'>
              <div className='flex flex-row'>
                <img
                  alt=''
                  src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/noun_Scientist_2872799.svg?alt=media&token=41a41d03-bb02-4f97-9eb3-87e677e8851c'
                />
                <p>
                  Scientists and researchers need diverse cell lines and types.
                  It's hard for them to find these. We've made it easy and you
                  can help!
                </p>
              </div>
            </div>
          </div>
          {/*  */}
          <h2>How it works</h2>
          <p>
            You can choose to choose to donate (or even sell - more on that in a
            bit) your stem cells to particular research groups and/or projects.
          </p>
          <figure>
            <img
              alt=''
              src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/Screen%20Shot%202020-12-28%20at%206.58.29%20PM.png?alt=media&token=1fcd6c86-e825-44ba-953e-ced6de9191dc'
            />
            <figcaption>
              You can tell us to produce more of your stem cells and give them
              (or sell them) to certain projects and groups. Remember, these are{' '}
              <em>your cells</em> and you have control over them.
            </figcaption>
          </figure>

          <p>
            Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus
            enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor
            praesent donec est. Odio penatibus risus viverra tellus varius sit
            neque erat velit.
          </p>

          <h2>How to use it</h2>
          <p>
            In Stembionix Portal, you go to open research opportunities and see
            if you want to contribute your cells to any of them. You are able to
            follow along and receive updates.
          </p>
        </div>

        <div className='mt-4' />
        <BuyMembershipCTA />
        {/* <ExchangeCTA /> */}

        {/*  */}
      </div>
    </div>
  );
};
