import React from 'react';
import { Helmet } from 'react-helmet';
import { CalloutImageWithText } from '../components/CalloutImageWithText';

import { IndustryHero } from '../components/heroes/IndustryHero';
import { IndustryWhyStemCells } from '../components/IndustryWhyStemCells';

export const IndustryPage = () => {
  return (
    <div>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <IndustryHero />
      <div className='mt-10' />
      <IndustryWhyStemCells />
      <CalloutImageWithText />
      {/* <div className='mt-10' /> */}
      {/* <BuyMembershipCTA /> */}
      {/* <div className='mt-10' /> */}
      {/* <StoreHero /> */}
    </div>
  );
};
