import React from 'react';
import { BuyMembershipCTA } from '../components/cta/BuyMembershipCTA';
import { ImageTextCTA } from '../components/cta/ImageTextCTA';
import { FAQ } from '../components/FAQ';

import { KitHero } from '../components/heroes/KitHero';
import { StoreHero } from '../components/heroes/StoreHero';
import { MarketIconCallout } from '../components/MarketIconCallout';
import { WhyStemCells } from '../components/WhyStemCells';
import { ThreeSteps } from './Process';

const Spacer = () => <div className='mt-10' />;

export const HomePage = () => {
  return (
    <div>
      <KitHero />
      {/* <Spacer /> */}
      {/* <MarketIconCallout /> */}
      {/* <Spacer /> */}
      <ImageTextCTA
        header='Join the Stem Cell Marketplace'
        subheader=''
        text="Participate in the world's first stem cell marketplace where you can sell, buy, and even trade your stem cells and derived cell types!"
        imageOnRight
        link='https://stembionix.com/pages/marketplace'
        img='https://cdn-prod.medicalnewstoday.com/content/images/articles/323/323343/stem-cells.jpg'
      />
      {/* <MarketIconCallout /> */}
      {/* <Spacer /> */}
      <ImageTextCTA
        header='Get. Save. Use.'
        subheader=''
        text='Our easy at home collection system allows you to save and grow your stem cells. Use them for cosmetic, medical, or even hobbyist reasons. We can ship them to you or a third-party.'
        link='https://stembionix.com/pages/use-cases'
      />
      {/* <Spacer /> */}
      {/* <ThreeSteps /> */}
      <Spacer />
      {/* <WhyStemCells /> */}
      <BuyMembershipCTA />
      <Spacer />
      <FAQ />
      {/* <StoreHero /> */}
    </div>
  );
};
