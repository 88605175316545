type Item = { title: string; about: string; examples: string[] };
const categories: Item[] = [
  {
    title: 'Hobbyist',
    about: 'Curious. Wants to experiment and engineer',
    examples: [
      'Grows stem and profliferates stem cells at home',
      'Experiments with types of cells to proliferate',
    ],
  },
  {
    title: 'Education',
    about: 'Curious. Wants to learn more either at home or in school',
    examples: [
      'Biology teaching giving inclass instructions',
      'At home parent/child educational opportunity',
      'Student doing oustide classroom learning',
    ],
  },
  {
    title: 'Cosmetic',
    about: 'Wants to use stem cells for therapies such as anti-aging',
    examples: ['Skin creams derived from own stem cells'],
  },
  {
    title: 'Medical',
    about:
      'Wants to use stem cells to address a current or future medical need.',
    examples: ['Use own stem cells for cancer treatments'],
  },
];

const Card = ({ item }: { item: Item }) => {
  return (
    <div className='border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200'>
      <div className='p-6'>
        <h2 className='text-lg leading-6 font-medium text-gray-900'>
          {item.title}
        </h2>
        <p className='mt-4 text-sm text-gray-500'>{item.about}</p>
        {/* <p className='mt-8'>
                <span className='text-4xl font-extrabold text-gray-900'>
                  $12
                </span>
                <span className='text-base font-medium text-gray-500'>/mo</span>
              </p>
              <a
                href='#'
                className='mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900'>
                Buy Hobby
              </a> */}
      </div>
      <div className='pt-6 pb-8 px-6'>
        <h3 className='text-xs font-medium text-gray-900 tracking-wide uppercase'>
          Examples
        </h3>
        <ul className='mt-6 space-y-4'>
          {item.examples.map((example) => (
            <li key={example} className='flex space-x-3'>
              <svg
                className='flex-shrink-0 h-5 w-5 text-green-500'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
                aria-hidden='true'>
                <path
                  fillRule='evenodd'
                  d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
                  clipRule='evenodd'
                />
              </svg>
              <span className='text-sm text-gray-500'>{example}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const WhyStemCells = () => {
  return (
    <div className='bg-white'>
      <div className='max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8'>
        <div className='sm:flex sm:flex-col sm:align-center'>
          <h1 className='text-5xl font-extrabold text-gray-900 sm:text-center'>
            Stem Cell User Cases
          </h1>
          <p className='mt-5 text-xl text-gray-500 sm:text-center'>
            Who uses stem cells? and how?
          </p>
        </div>
        <div className='mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4'>
          {categories.map((category) => (
            <Card key={category.title} item={category} />
          ))}
        </div>
      </div>
    </div>
  );
};
