// TODO: want a better callout than what we have for bottom of home page
export const CalloutImageWithText = () => {
  return (
    <div className=''>
      <div className='h-10 float-right'>
        <img
          src='https://images.unsplash.com/photo-1596120718374-028fba035262?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1604&q=80'
          alt=''
          className='h-64 object-cover'
        />
      </div>
      {/*  */}
      <div className='p-10 text-center'>
        <h1>Early Adopter</h1>
        <p>about stuff</p>
        <button className='px-4 py-2 rounded-lg bg-skyblue'>Learn more</button>
      </div>
    </div>
  );
};
