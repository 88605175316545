import { BuyMembershipCTA } from '../components/cta/BuyMembershipCTA';

export const HobbyistPage = () => {
  return (
    <div>
      <div className='py-16 bg-gray-50 overflow-hidden lg:py-24'>
        <div className='relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl'>
          <svg
            className='hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4'
            width='404'
            height='784'
            fill='none'
            viewBox='0 0 404 784'
            aria-hidden='true'>
            <defs>
              <pattern
                id='b1e6e422-73f8-40a6-b5d9-c8586e37e0e7'
                x='0'
                y='0'
                width='20'
                height='20'
                patternUnits='userSpaceOnUse'>
                <rect
                  x='0'
                  y='0'
                  width='4'
                  height='4'
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width='404'
              height='784'
              fill='url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)'
            />
          </svg>

          <div className='relative'>
            <h2 className='text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
              Personal <span className='text-skyblue'> Stem Cells</span>
              <br />
              for
              <br />
              <span className='text-skyblue'>Hobbyist</span>
            </h2>
            <p className='mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500'>
              You're a biotechnology hobbyist - or maybe even past the hobby
              stage - and you are looking for the highest quality personal stem
              cells.
            </p>
          </div>

          <div className='relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center'>
            <div className='relative'>
              <h3 className='text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl'>
                What will you do with Stem Cells and Cell Types?
              </h3>
              <p className='mt-3 text-lg text-gray-500'>
                We are able to offer you the highest quality stem cells{' '}
                <a
                  className='underline'
                  href='https://stembionix.com/pages/process'>
                  derived from your own personal cells.{' '}
                </a>
                You'll have access to not only ordering quantities of your stem
                cells but also of derived cell types such as neurons. With these
                cells you could do such things as
              </p>

              <dl className='mt-10 space-y-10'>
                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <div className='flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white'>
                      <svg
                        className='h-6 w-6'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        aria-hidden='true'>
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          d='M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9'
                        />
                      </svg>
                    </div>
                  </div>
                  <div className='ml-4'>
                    <dt className='text-lg leading-6 font-medium text-gray-900'>
                      Learn to differentiate and control cell types
                    </dt>
                    <dd className='mt-2 text-base text-gray-500'>
                      Take your personal stem cells and learn to differentiate
                      them into other cell types or even make new types
                      altogether.
                    </dd>
                  </div>
                </div>

                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <div className='flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white'>
                      <svg
                        className='h-6 w-6'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        aria-hidden='true'>
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          d='M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3'
                        />
                      </svg>
                    </div>
                  </div>
                  <div className='ml-4'>
                    <dt className='text-lg leading-6 font-medium text-gray-900'>
                      Pair stem cells with crispr
                    </dt>
                    <dd className='mt-2 text-base text-gray-500'>
                      Combine your personalized stem cell with at-home crispr
                      technologies.
                    </dd>
                  </div>
                </div>

                <div className='flex'>
                  <div className='flex-shrink-0'>
                    <div className='flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white'>
                      <svg
                        className='h-6 w-6'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        aria-hidden='true'>
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='2'
                          d='M13 10V3L4 14h7v7l9-11h-7z'
                        />
                      </svg>
                    </div>
                  </div>
                  <div className='ml-4'>
                    <dt className='text-lg leading-6 font-medium text-gray-900'>
                      Explore stem cells as a form of art
                    </dt>
                    <dd className='mt-2 text-base text-gray-500'>
                      Use your stem cells in art projects.
                    </dd>
                  </div>
                </div>
              </dl>
            </div>

            <div className='mt-10 -mx-4 relative lg:mt-0' aria-hidden='true'>
              <svg
                className='absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden'
                width='784'
                height='404'
                fill='none'
                viewBox='0 0 784 404'>
                <defs>
                  <pattern
                    id='ca9667ae-9f92-4be7-abcb-9e3d727f2941'
                    x='0'
                    y='0'
                    width='20'
                    height='20'
                    patternUnits='userSpaceOnUse'>
                    <rect
                      x='0'
                      y='0'
                      width='4'
                      height='4'
                      className='text-gray-200'
                      fill='currentColor'
                    />
                  </pattern>
                </defs>
                <rect
                  width='784'
                  height='404'
                  fill='url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)'
                />
              </svg>
              <img
                className='relative mx-auto'
                width='490'
                src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/in-a-world-first-researchers-hijack-bacteria-to-guide-stem-cells-home-321487.webp?alt=media&token=781b1a5c-3f87-40ea-8f4d-aed73fac31cd'
                alt=''
              />
            </div>
          </div>

          <svg
            className='hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12'
            width='404'
            height='784'
            fill='none'
            viewBox='0 0 404 784'
            aria-hidden='true'>
            <defs>
              <pattern
                id='64e643ad-2176-4f86-b3d7-f2c5da3b6a6d'
                x='0'
                y='0'
                width='20'
                height='20'
                patternUnits='userSpaceOnUse'>
                <rect
                  x='0'
                  y='0'
                  width='4'
                  height='4'
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width='404'
              height='784'
              fill='url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)'
            />
          </svg>

          <div className='relative mt-12 sm:mt-16 lg:mt-24'>
            <div className='lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center'>
              <div className='lg:col-start-2'>
                <h3 className='text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl'>
                  Connect with the Stembionix Community
                </h3>
                <p className='mt-3 text-lg text-gray-500'>
                  We are growing a community of not only hobbyist but also top
                  tier researchers from across the academy and industry as well
                  as clients who want to use stem cells and stem cell derived
                  technologies.
                </p>

                <dl className='mt-10 space-y-10'>
                  <div className='flex'>
                    <div className='flex-shrink-0'>
                      <div className='flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white'>
                        <svg
                          className='h-6 w-6'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          aria-hidden='true'>
                          <path
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            d='M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z'
                          />
                        </svg>
                      </div>
                    </div>
                    <div className='ml-4'>
                      <dt className='text-lg leading-6 font-medium text-gray-900'>
                        Learn about new findings
                      </dt>
                      <dd className='mt-2 text-base text-gray-500'>
                        Connect with others to stay up-to-date on the latest in
                        stem cell related discoveries.
                      </dd>
                    </div>
                  </div>

                  <div className='flex'>
                    <div className='flex-shrink-0'>
                      <div className='flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white'>
                        <svg
                          className='h-6 w-6'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          aria-hidden='true'>
                          <path
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
                          />
                        </svg>
                      </div>
                    </div>
                    <div className='ml-4'>
                      <dt className='text-lg leading-6 font-medium text-gray-900'>
                        Help others
                      </dt>
                      <dd className='mt-2 text-base text-gray-500'>
                        Make connections to find help and help others.
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>

              <div className='mt-10 -mx-4 relative lg:mt-0 lg:col-start-1'>
                <svg
                  className='absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden'
                  width='784'
                  height='404'
                  fill='none'
                  viewBox='0 0 784 404'
                  aria-hidden='true'>
                  <defs>
                    <pattern
                      id='e80155a9-dfde-425a-b5ea-1f6fadd20131'
                      x='0'
                      y='0'
                      width='20'
                      height='20'
                      patternUnits='userSpaceOnUse'>
                      <rect
                        x='0'
                        y='0'
                        width='4'
                        height='4'
                        className='text-gray-200'
                        fill='currentColor'
                      />
                    </pattern>
                  </defs>
                  <rect
                    width='784'
                    height='404'
                    fill='url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)'
                  />
                </svg>
                <img
                  className='relative mx-auto'
                  width='490'
                  src='https://firebasestorage.googleapis.com/v0/b/stembionix-webapp.appspot.com/o/Screen%20Shot%202020-12-23%20at%205.56.27%20PM.png?alt=media&token=765300aa-e598-42c1-a404-a1a537093fef'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mt-5' />
      <BuyMembershipCTA />
    </div>
  );
};
