import React from 'react';
import { Link } from 'react-router-dom';

export const IndustryHero = () => {
  // const isSignedIn = useSelector((app: AppState) => app.account.isSignedIn);
  const isSignedIn = false;

  return (
    <div
      className='lg:relative'
      style={{
        backgroundImage: 'url(/marcel-strauss-DPIc3aeQLxY-unsplash.jpg)',
      }}>
      <div className='mx-auto max-w-7xl w-full xs:pt-4 sm:pt-16 pb-20 text-center lg:py-48 lg:text-left'>
        <div className='px-4 lg:w-1/2 sm:px-8 xl:pr-16'>
          <h2 className='text-4xl tracking-tight leading-10 font-extrabold text-gray-300 sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl'>
            Welcome to the Industrial <br className='xl:hidden' />
            <span className='text-skyblue'>Stem Cell </span>Revolution
          </h2>
          <p className='mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl'>
            {/* Brought to you by The Human Flourishing Program at Harvard
            University's Institute for Quantitative Social Science. */}
          </p>
          {!isSignedIn && (
            <div className='mt-10 sm:flex sm:justify-center lg:justify-start'>
              <div className='rounded-md shadow'>
                <Link
                  to='about'
                  className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-skyblue hover:bg-blue-200 focus:outline-none focus:border-gray-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10'>
                  Learn more
                </Link>
              </div>
              <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
                <Link
                  to='contact'
                  className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-skyblue bg-blue-100 hover:text-skyblue focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10'>
                  Contact sales
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='relative w-full h-48 sm:h-64 md:h-64 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full'>
        <img
          className='absolute inset-0 w-full h-full object-contain'
          src={'plasmid.png'}
          alt='Stembionix logo'
        />
      </div>
    </div>
  );
};
