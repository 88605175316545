import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Router } from './router/Router';
import { ScrollToTop } from './router/ScrollToTop';
import { Footer } from './components/Footer';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <div className='min-h-screen mt-0'>
          {process.env.NODE_ENV === 'production' && <ScrollToTop />}
          <Router />
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
